<template>
  <div v-if="!isLoading">
    <HeaderPanel
      title="Coupon Analysis"
      :filter="filter"
      @sidebar="sidebarFilter"
      :hasDropdown="false"
      :hasSearch="false"
      :hasExport="true"
      @export="exportCouponCustomer"
    />
    <p class="my-2 f-16">
      Total Customer : {{ stat.totalCustomer | numeral("0,0") }} ({{
        stat.totalCustomerPercent.toFixed(2)
      }}%)
    </p>
    <p class="my-2 f-16">
      {{
        filter.LastType == 0
          ? `${$moment(filter.dateFrom).format("DD MMM YYYY")} - ${$moment(
              filter.dateTo
            ).format("DD MMM YYYY")}`
          : `${$displayFilterDate(filter.LastType)}`
      }}
    </p>
    <StatPanel :column="statColumn" :data="stat" :isLoading="statLoading" />

    <div class="chart-bg pt-3">
      <b-container>
        <b-row class="chart-gap">
          <b-col cols="12">
            <b-card class="shadow-sm border-none text-black chart-rounded">
              <b-tabs
                class="tab-menu-list"
                v-model="tabIndex"
                content-class="pt-3"
              >
                <b-tab title="Usage All Coupon" class="text-nowrap">
                  <div class="d-flex justify-content-between mb-3">
                    <div class="chart-title-name">Top 10 Coupon Usage</div>
                    <div class="d-flex" style="column-gap: 5px">
                      <div :class="`f-14 underline cursor-pointer`">
                        <!-- @click="hadleChartFilter(chartFilter, chart)" -->

                        <span
                          class="text-highlight"
                          @click="exportExcel(1, 'coupon-analysis')"
                          >Export</span
                        >
                      </div>
                    </div>
                  </div>

                  <Chart
                    id="usage-coupon"
                    :chartData="top10CouponByDate"
                    text="Number Of Usage"
                    class="mt-auto"
                    :label="top10CouponByDateLabel"
                    :hideLegend="false"
                    type="bar"
                    v-if="!isChartLoading.usage"
                  />

                  <ChartLoading
                    v-else
                    :id="'loading-' + 'promotions'"
                    type="bar"
                  />
                </b-tab>
                <b-tab title="Personalized Coupon" class="text-nowrap">
                  <div class="d-flex justify-content-between mb-3">
                    <div class="chart-title-name">
                      Top 10 Personalize Coupon
                    </div>
                    <div class="d-flex" style="column-gap: 5px">
                      <div :class="`f-14 underline cursor-pointer`">
                        <!-- @click="hadleChartFilter(chartFilter, chart)" -->

                        <span
                          class="text-highlight"
                          @click="exportExcel(1, 'coupon-analysis')"
                          >Export</span
                        >
                      </div>
                    </div>
                  </div>

                  <Chart
                    id="personalize-coupon"
                    :chartData="top10CouponPersonalize"
                    text="Number Of Usage"
                    class="mt-auto"
                    :label="top10CouponPersonalizeLabel"
                    :hideLegend="false"
                    type="bar"
                    v-if="!isChartLoading.personalize"
                  />

                  <ChartLoading
                    v-else
                    :id="'loading-' + 'promotions'"
                    type="bar"
                  />
                </b-tab>
                <b-tab title="Publish Coupon" class="text-nowrap">
                  <div class="d-flex justify-content-between mb-3">
                    <div class="chart-title-name">Top 10 Publish Coupon</div>
                    <div class="d-flex" style="column-gap: 5px">
                      <div :class="`f-14 underline cursor-pointer`">
                        <!-- @click="hadleChartFilter(chartFilter, chart)" -->

                        <span
                          class="text-highlight"
                          @click="exportExcel(1, 'coupon-analysis')"
                          >Export</span
                        >
                      </div>
                    </div>
                  </div>

                  <Chart
                    id="public-coupon"
                    :chartData="top10CouponPublic"
                    text="Number Of Usage"
                    class="mt-auto"
                    :label="top10CouponPublicLabel"
                    :hideLegend="false"
                    type="bar"
                    v-if="!isChartLoading.public"
                  />

                  <ChartLoading v-else :id="'loading-' + 'public'" type="bar" />
                </b-tab>
              </b-tabs>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card class="shadow-sm border-none text-black chart-rounded">
              <div class="d-flex justify-content-between mb-3">
                <div class="chart-title-name">Coupon</div>
                <div class="d-flex align-items-center" style="column-gap: 5px">
                  <div :class="`f-14 underline cursor-pointer`">
                    <b-dropdown id="dropdown" right class="btn-mobile mr-2">
                      <template #button-content>
                        <span class="d-none d-md-inline-flex btn-text mr-1"
                          >Action</span
                        >
                      </template>
                      <slot name="content-dropdown">
                        <b-dropdown-item @click="exportExcel(2, 'coupon')">
                          <span>
                            <!-- <font-awesome-icon icon="file-import" class="pointer mr-2" /> -->
                          </span>
                          <span>Export</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="exportExcel(4, 'coupon')">
                          <span>
                            <!-- <font-awesome-icon icon="file-export" class="pointer mr-2" /> -->
                          </span>
                          <span>Export Detail</span>
                        </b-dropdown-item>
                      </slot>
                    </b-dropdown>
                  </div>
                </div>
              </div>
              <b-table
                fixed
                striped
                hover
                :fields="fields"
                :items="items"
                :busy="isBusy"
                show-empty
                empty-text="No matching records found"
                foot-clone
                no-footer-sorting
                @sort-changed="sortingCoupon"
                :sort-by.sync="filterTable.sortBy"
                no-local-sorting
                :sort-desc.sync="filterTable.sortDesc"
              >
                <template #cell(revanue)="{ item }">
                  {{ item.revanue | numeral("0,0.00") }} ({{
                    ((item.revanue / subTotal.coupon["revenueFromCoupon"]) *
                      100)
                      | numeral("0,0.00")
                  }}%)
                </template>
                <template v-slot:foot()="data">
                  <span
                    v-if="subTotal.coupon[data.field.key]"
                    class="text-center"
                  >
                    <span
                      v-if="
                        data.field.key == 'numOfRedeem' ||
                        data.field.key == 'numOfRedeemUse'
                      "
                    >
                      {{
                        subTotal.coupon[data.field.key] | numeral("0,0")
                      }}</span
                    >
                    <span v-else>
                      {{
                        subTotal.coupon[data.field.key] | numeral("0,0.00")
                      }}</span
                    >
                  </span>
                  <span v-else>
                    <div class="text-left px-2" v-if="data.field.text">
                      {{ data.field.text }}
                    </div>
                  </span>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
              <Pagination
                @handleChangeTake="handleChangeTake"
                @pagination="pagination"
                :filter="filterTable"
                :rows="rows"
                :limit="2" /></b-card
          ></b-col>
          <b-col cols="12">
            <b-card class="shadow-sm border-none text-black chart-rounded">
              <div class="d-flex justify-content-between">
                <div class="chart-title-name">Product</div>
                <div class="d-flex align-items-center" style="column-gap: 5px">
                  <b-form-input
                    type="text"
                    id="header-search-bar"
                    class="search-bar mb-2"
                    @keyup.enter="handleSearch"
                    placeholder="Search Name, Barcode"
                    v-model="filterProductTable.search"
                    style="width: 300px"
                  >
                  </b-form-input>

                  <div :class="`f-14 underline cursor-pointer`">
                    <!-- @click="hadleChartFilter(chartFilter, chart)" -->
                    <span
                      class="text-highlight"
                      @click="exportExcel(3, 'product')"
                      >Export</span
                    >
                  </div>
                </div>
              </div>
              <b-table
                fixed
                striped
                hover
                :fields="fieldsProduct"
                :items="itemsProduct"
                :busy="isProductBusy"
                show-empty
                empty-text="No matching records found"
                foot-clone
                no-footer-sorting
                @sort-changed="sortingProduct"
                :sort-by.sync="filterTable.sortBy"
                no-local-sorting
                :sort-desc.sync="filterTable.sortDesc"
              >
                <template #cell(customer)="{ item }">
                  {{ item.customer | numeral("0,0") }} (
                  {{
                    ((item.customer / subTotal.product["members"]) * 100)
                      | numeral("0.00")
                  }}%)
                </template>
                <template #cell(totalSales)="{ item }">
                  {{ item.totalSales | numeral("0,0") }}
                </template>
                <template #cell(totalQuantity)="{ item }">
                  {{ item.totalQuantity | numeral("0,0") }}
                </template>
                <template #cell(totalBills)="{ item }">
                  {{ item.totalBills | numeral("0,0") }}
                </template>
                <template #cell(atv)="{ item }">
                  {{ item.atv | numeral("0,0.00") }}
                </template>
                <template #cell(members)="{ item }">
                  {{ item.members | numeral("0,0.00") }} ({{
                    item.percenMembers
                  }}%)
                </template>
                <template v-slot:cell(percenSalesContribution)="data">
                  <span>
                    {{
                      ((data.item.totalSales / subTotal.product["revenue"]) *
                        100)
                        | numeral("0.00")
                    }}%
                  </span>
                </template>
                <template v-slot:foot()="data">
                  <span
                    v-if="subTotal.product[data.field.key]"
                    class="text-center"
                  >
                    <span
                      v-if="
                        data.field.key == 'totalQuantity' ||
                        data.field.key == 'customer' ||
                        data.field.key == 'totalSales' ||
                        data.field.key == 'totalBills'
                      "
                    >
                      {{
                        subTotal.product[data.field.key] | numeral("0,0")
                      }}</span
                    >
                    <span v-else>
                      {{
                        subTotal.product[data.field.key] | numeral("0,0.00")
                      }}</span
                    >
                  </span>
                  <span v-else>
                    <div class="text-left px-2" v-if="data.field.text">
                      {{ data.field.text }}
                    </div>
                  </span>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
              <Pagination
                @handleChangeTake="handleProductChangeTake"
                @pagination="paginationProduct"
                :filter="filterProductTable"
                :rows="rowsProduct"
                :limit="2" /></b-card
          ></b-col>
          <b-col md="12">
            <b-card class="card-shadow border-none text-black chart-rounded">
              <div class="d-flex justify-content-between mb-3">
                <div class="chart-title-name">Branch</div>

                <div :class="`f-14 underline cursor-pointer`">
                  <!-- @click="hadleChartFilter(chartFilter, chart)" -->
                  <span
                    class="text-highlight"
                    @click="exportExcel(5, 'coupon-branch-report')"
                    >Export</span
                  >
                </div>
              </div>
              <b-table
                fixed
                striped
                hover
                :fields="fieldsBranch"
                :items="itemsBranch"
                :busy="isBusy"
                show-empty
                empty-text="No matching records found"
                foot-clone
                no-footer-sorting
                @sort-changed="sortingChanged"
                :sort-by.sync="filterTable.sortBy"
                no-local-sorting
                :sort-desc.sync="filterTable.sortDesc"
              >
                <template #cell(revenue)="{ item }">
                  {{ item.revenue | numeral("0,0.00") }} ({{
                    ((item.revenue / statBranch["revenue"]) * 100)
                      | numeral("0.00")
                  }}%)
                </template>
                <template #cell(branchUPT)="{ item }">
                  {{ item.branchUPT | numeral("0,0.00") }}
                </template>
                <template #cell(branchATV)="{ item }">
                  {{ item.branchATV | numeral("0,0.00") }}
                </template>
                <template #cell(units)="{ item }">
                  {{ item.units | numeral("0,0") }} ({{
                    ((item.units / statBranch["units"]) * 100)
                      | numeral("0.00")
                  }}%)
                </template>
                <template #cell(discount_value)="{ item }">
                  {{ item.discount_value | numeral("0,0") }}
                </template>
                <template #cell(bills)="{ item }">
                  {{ item.bills | numeral("0,0") }} ({{
                    ((item.bills / statBranch["bills"]) * 100)
                      | numeral("0.00")
                  }}%)
                </template>
                <template #cell(members)="{ item }">
                  {{ item.members | numeral("0,0") }} ({{
                    ((item.members / statBranch["members"]) * 100)
                      | numeral("0.00")
                  }}%)
                </template>
                <template #cell(newMembers)="{ item }">
                  {{ item.newMembers | numeral("0,0") }} ({{
                    ((item.newMembers / statBranch["newMembers"]) * 100)
                      | numeral("0.00")
                  }}%)
                </template>
                <template v-slot:foot()="data">
                  <span
                    v-if="
                      statBranch[data.field.key] >= 0 ||
                      statBranch[data.field.stat]
                    "
                    class="text-center"
                  >
                    <span
                      v-if="
                        data.field.key == 'bills' ||
                        data.field.key == 'units' ||
                        data.field.key == 'newMembers' ||
                        data.field.key == 'members'
                      "
                    >
                      {{ statBranch[data.field.key] | numeral("0,0") }}</span
                    >
                    <span v-else>
                      {{
                        statBranch[data.field.key] ||
                        statBranch[data.field.stat] | numeral("0,0.00")
                      }}</span
                    >
                  </span>
                  <span v-else>
                    <div class="text-left px-2" v-if="data.field.text">
                      {{ data.field.text }}
                    </div>
                  </span>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
              <Pagination
                @handleChangeTake="handleChangeTakeBranch"
                @pagination="paginationBranch"
                :filter="filterBranch"
                :rows="rowsBranch"
                :limit="2"
              /> </b-card></b-col
        ></b-row>
      </b-container>
    </div>

    <SideBarFilter
      ref="sidebarFilter"
      :filter="filter"
      :placeholder="'Search Name'"
      @clearFilter="clearFilter"
      @searchAll="filterActions"
      :hideStatusFilter="false"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <FilterCustomDate
          customText="Date"
          id="10"
          :from="filter.DateFrom"
          :to="filter.DateTo"
          :type="filter.LastType"
          @update:type="(value) => (filter.LastType = value)"
          @update:from="(value) => (filter.DateFrom = value)"
          @update:to="(value) => (filter.DateTo = value)"
        />

        <div class="mb-3">
          <InputDatePickerFilter
            textFloat="Transaction Date From"
            name="transaction-date-from"
            ref="transaction-date-from"
            :max-datetime="filter.TransactionDateTo || initFilterDate.MaxDate"
            :value="filter.TransactionDateFrom"
            @input="(val) => (filter.TransactionDateFrom = val)"
          />
        </div>
        <div class="mb-3">
          <InputDatePickerFilter
            textFloat="Transaction Date To"
            name="transaction-date-to"
            ref="transaction-date-to"
            :min-datetime="filter.TransactionDateFrom || initFilterDate.MinDate"
            :value="filter.TransactionDateTo"
            @input="(val) => (filter.TransactionDateTo = val)"
          />
        </div>
        <div class="my-3">
          <MultiSelect
            textFloat="Member tier"
            :options="options.memberTiers"
            textField="memberTierName"
            valueField="id"
            placeholder="Select Member Tier"
            v-model="filter.MemberTier"
            :value="filter.MemberTier"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            textFloat="Coupon Code"
            :options="options.coupon"
            textField="name"
            valueField="id"
            placeholder="Select Coupon Code"
            v-model="filter.CouponCode"
            :value="filter.CouponCode"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            textFloat="Privilege Code"
            :options="options.privailegeCode"
            textField="name"
            valueField="id"
            placeholder="Select Privilege Code"
            v-model="filter.PrivilegeCode"
            :value="filter.PrivilegeCode"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            textFloat="Purchase Branch"
            :options="options.branches"
            textField="branchName"
            valueField="id"
            placeholder="Select Purchase Branch"
            v-model="filter.BranchFilter"
            :value="filter.BranchFilter"
          />
        </div>
        <check-box-anonymous
          :value="filter.allow_anonymous"
          @change="(val) => (filter.allow_anonymous = val)"
          class="mb-3"
        />
      </template>
    </SideBarFilter>
    <ModalInputEmail
      ref="ModalInputEmail"
      :form="filterExportFile"
      @closeModal="clearExportFilter"
      @changeEmail="(val) => (filterExportFile.email = val)"
      @submit="exportCouponCustomer"
    />
  </div>
</template>

<script>
import ModalInputEmail from "@/components/customer/ModalInputEmail";
import StatPanel from "@/components/StatPanel";
import ChartLoading from "@/components/loading/ChartLoading";
import Chart from "chart";

import Multiselect from "vue-multiselect";
const moment = require("moment");
import InputDatePickerFilter from "@/components/inputs/InputDatePickerFilter";
export default {
  components: {
    Chart,
    Multiselect,
    InputDatePickerFilter,
    ModalInputEmail,
    StatPanel,
    ChartLoading,
  },
  data() {
    return {
      isBusy: false,
      isProductBusy: false,
      isChartLoading: {
        usage: true,
        personalize: true,
        public: true,
      },
      statColumn: [
        {
          name: "Total Coupon Redeem",
          key: "totalCouponRedeem",
          isPercent: false,
          isDecimal: false,
        },
        {
          name: "Total Coupon Use",
          key: "totalCouponUsed",
          isPercent: false,
          isDecimal: false,
        },
        {
          name: "Revenue From Coupon",
          key: "revenueFromCoupon",
          isPercent: false,
          isDecimal: false,
        },
      ],
      statLoading: true,
      fields: [
        {
          key: "name",
          label: "Name",
          class: "w-40 text-left",
          sortable: true,
          text: "Total",
        },
        {
          key: "numOfRedeem",
          label: "Number Of Redeem",
          class: "w-15",
          sortable: true,
        },
        {
          key: "numOfRedeemUse",
          label: "Number Of Use",
          class: "w-15",
          sortable: true,
        },
        {
          key: "revanue",
          label: "Revenue (% Of Grand Total)",
          class: "w-15",
          sortable: true,
        },
      ],
      fieldsProduct: [
        { key: "name", label: "Name", class: "w-3", sortable: true },
        {
          key: "barcode",
          label: "Barcode",
          class: "w-auto",
          sortable: true,
          text: "Total",
        },

        {
          key: "customer",
          label: "% Customer",
          class: "w-auto",
          sortable: true,
        },
        {
          key: "totalSales",
          label: "Total Sales",
          class: "w-auto",
          sortable: true,
        },
        {
          key: "totalQuantity",
          label: "Total Quantity",
          class: "w-auto",
          sortable: true,
        },
        {
          key: "totalBills",
          label: "Total Bills",
          class: "w-auto",
          sortable: true,
        },
        { key: "atv", label: "ATV", class: "w-auto", sortable: true },
        {
          key: "percenSalesContribution",
          label: "% Sales Contribution",
          class: "w-auto",
        },
      ],
      stat: {
        totalCustomer: 0,
        totalCustomerPercent: 0,
        totalCouponRedeem: 0,
        totalCouponUsed: 0,
        revenueFromCoupon: 0,
        dateFrom: "",
        dateTo: "",
      },
      statBranch: {},
      filter: {
        // DateFrom: moment().subtract(1, "month").format("YYYY-MM-DD"),
        // DateTo: moment().format("YYYY-MM-DD"),
        TransactionDateFrom: moment().subtract(1, "month").format("YYYY-MM-DD"),
        TransactionDateTo: moment().format("YYYY-MM-DD"),
        Search: "",
        MemberTier: this.$store.state.filter.tier,
        BranchFilter: this.$store.state.filter.branch,
        CouponCode: [],
        PrivilegeCode: [],
        Page: 1,
        GraphType: 2,
        take: 10,
        Mode: 0,
        DateFrom: this.$store.state.filter.from,
        DateTo: this.$store.state.filter.to,
        TypeDateFilter: 2,
        LastType: this.$store.state.filter.dateType,
        allow_anonymous: this.$store.state.filter.allow_anonymous,
      },
      fieldsBranch: [
        {
          key: "branchName",
          label: "Store",

          sortable: true,
          text: "Total",
        },
        { key: "channelName", label: "Channel", sortable: true, class: "w-1" },
        { key: "revenue", label: "Total Sales", class: "w-2", sortable: true },
        {
          key: "discount_value",
          label: "Discount",
          class: "w-1",
          sortable: true,
        },
        {
          key: "branchATV",
          stat: "atv",
          label: "ATV",
          class: "w-1",
          sortable: true,
        },
        {
          key: "branchUPT",
          stat: "upt",
          label: "UPT",
          class: "w-1",
          sortable: true,
        },
        { key: "units", label: "Units", class: "w-1", sortable: true },
        { key: "bills", label: "Bills", class: "w-1", sortable: true },
        { key: "members", label: "Members", class: "w-1" },
        {
          key: "newMembers",
          label: "New Members",
          class: "w-1",
        },
      ],
      itemsBranch: [],

      filterExportFile: {
        email: "",
      },
      filterTable: {
        page: 1,
        take: 10,
        search: "",
        SortColumnId: 0,
        SortType: true,
        sortBy: "",
        sortDesc: "",
      },
      rowsBranch: 0,
      filterBranch: {
        page: 1,
        take: 10,
        search: "",
        SortColumnId: 0,
        SortType: true,
        sortBy: "",
        sortDesc: "",
      },
      filterProductTable: {
        page: 1,
        take: 10,
        search: "",
        SortColumnId: 0,
        SortType: true,
        sortBy: "",
        sortDesc: "",
      },
      loading: true,
      loading2: true,
      items: [],
      itemsProduct: [],
      rows: 0,
      rowsProduct: 0,
      chartAgeData: [],
      chartAgeLabel: [],
      top10CouponByDate: [],
      top10CouponByDateLabel: [],
      top10CouponPersonalize: [],
      top10CouponPersonalizeLabel: [],
      top10CouponPublic: [],
      top10CouponPublicLabel: [],
      options: {
        memberTiers: [],
        privailegeCode: [],
        coupon: [],
        ageGroups: [],
        genderModels: [],
        dateType: [
          { text: "Last 7 Days", value: 1 },
          { text: "Last 30 Days", value: 2 },
          { text: "Last 365 Days", value: 3 },
          { text: "Custom", value: 0 },
        ],
        // product: [],
        // transactionDate: [],
      },
      subTotal: {
        coupon: {},
        product: {},
      },
      tabIndex: 0,
    };
  },
  async created() {
    this.isLoading = true;
    this.getOptions();
    this.getPrivilage();
    this.getMemberTiers();
    this.getData();
    this.isLoading = false;
  },
  watch: {
    tabIndex(val) {
      if (val == 1) this.isChartLoading.personalize = false;
      if (val == 2) this.isChartLoading.public = false;
    },
  },
  methods: {
    async getData() {
      // this.$bus.$emit("showLoading");

      this.getCoupon();
      this.getBranch();
      this.getProduct();
      const res = await this.$report.post(
        `/DashBoard/coupon/default`,
        this.filter
      );
      this.getStat(
        res.data.detail.result_analysis,
        res.data.detail.result_branch_subtotal
      );

      this.getTotal(
        res.data.detail.result_analysis,
        res.data.detail.result_branch_subtotal
      );
      this.getCouponChart(res.data.detail.result_top10usage);
      this.getCouponPersonalizeChart(res.data.detail.result_top10personalize);
      this.getCouponPublic(res.data.detail.result_top10publish);

      // this.$bus.$emit("hideLoading");
    },
    async getStat(data, total) {
      this.statLoading = true;
      var respData = data;
      var total = total;
      if (!data) {
        const res = await this.$report.post(
          `/dashboard/CouponAnalysis`,
          this.filter
        );

        respData = res.data.detail;
      }
      if (!total) {
        const result = await this.$report.post(
          `/dashboard/CouponAnalysis/Branch/sub_total`,
          this.filter
        );
        total = result.data.detail;
      }
      this.stat = respData;
      this.statBranch = total;
      this.statLoading = false;
    },
    async getTotal(data, total) {
      var respData = data;
      var total = total;
      if (!data) {
        const res_coupn = await this.$report.post(
          `/Dashboard/CouponAnalysis/Coupon_Subtotal`,
          this.filter
        );

        respData = res_coupn.data.detail;
      }
      if (!total) {
        const res_product = await this.$report.post(
          `/Dashboard/CouponAnalysis/Product_Subtotal`,
          this.filter
        );
        total = res_product.data.detail;
      }

      this.subTotal.coupon = respData;

      this.subTotal.product = total;
    },
    async getOptions() {
      const couponCode = await this.$report(`/dashboard/Filter/CouponCode`);
      this.options.coupon = couponCode.data.detail;
      // this.options.coupon = res.data.detail;

      // this.stat = res.data.detail;
    },
    async getPrivilage() {
      const privilegeCode = await this.$report(
        `/dashboard/Filter/PrivilegeCode`
      );
      this.options.privailegeCode = privilegeCode.data.detail;
    },
    async getMemberTiers() {
      const memberTier = await this.$report(`/RevenueReport/CustomFilter`);
      this.options.memberTiers = memberTier.data.memberTiers;
      this.options.branches = memberTier.data.branches;
    },

    async getCouponChart(data) {
      this.isChartLoading.usage = true;
      var respData = await data;
      if (!data) {
        const res = await this.$report.post(
          `/dashboard/coupon/top10usage`,
          this.filter
        );
        respData = res.data.detail;
      }

      this.top10CouponByDateLabel = await respData.map((el) => el.coupon_name);

      var data = [];
      let index = 0;
      for (const x of respData) {
        if (index == 0) {
          var color = await this.$store.dispatch("getChartColor", 0);

          data.push({
            label: "Usage",
            type: "bar",
            data: [x.total],
            // group: true,
            backgroundColor: color,
          });
        } else {
          data[0].data.push(x.total);
        }
        index++;
      }

      this.top10CouponByDate = data;

      this.isChartLoading.usage = false;
    },
    async getCouponPersonalizeChart(data) {
      this.isChartLoading.personalize = true;
      var respData = await data;
      if (!data) {
        const res = await this.$report.post(
          `/dashboard/coupon/top10personalize`,
          this.filter
        );
        respData = res.data.detail;
      }

      this.top10CouponPersonalizeLabel = await respData.map(
        (el) => el.coupon_name
      );

      var data = [];
      let index = 0;
      for (const x of respData) {
        if (index == 0) {
          var color = await this.$store.dispatch("getChartColor", 1);
          var fade = await this.$store.dispatch("getChartColor", {
            index: 1,
            fade: true,
          });
          data.push(
            {
              label: "Redeemed",
              type: "bar",
              data: [x.total],
              // group: true,
              backgroundColor: color,
            },
            {
              label: "Usage",
              type: "bar",
              data: [x.total_used],
              // group: true,
              backgroundColor: fade,
            }
          );
        } else {
          data[0].data.push(x.total);
          data[1].data.push(x.total_used);
        }
        index++;
      }

      this.top10CouponPersonalize = data;

      // this.isChartLoading.personalize = false;
    },
    async getCouponPublic(data) {
      this.isChartLoading.public = true;
      var respData = await data;
      if (!data) {
        const res = await this.$report.post(
          `/dashboard/coupon/top10personalize`,
          this.filter
        );
        respData = res.data.detail;
      }

      this.top10CouponPublicLabel = await respData.map((el) => el.coupon_name);

      var data = [];
      let index = 0;
      for (const x of respData) {
        if (index == 0) {
          var color = await this.$store.dispatch("getChartColor", 2);

          data.push({
            label: "Usage",
            type: "bar",
            data: [x.total],

            backgroundColor: color,
          });
        } else {
          data[0].data.push(x.total);
        }
        index++;
      }

      this.top10CouponPublic = data;

      // this.isChartLoading.personalize = false;
    },
    async getCoupon() {
      this.isBusy = true;
      let payLoad = { ...this.filter };
      payLoad.Page = this.filterTable.page;
      payLoad.take = this.filterTable.take;
      payLoad.SortType = this.filterTable.SortType;
      payLoad.SortColumnId = this.filterTable.SortColumnId;

      const res = await this.$report.post(
        `/dashboard/CouponAnalysis/Coupon`,
        payLoad
      );

      this.items = res.data.detail.data;
      this.rows = res.data.detail.total;
      this.isBusy = false;
    },
    async getBranch() {
      this.isBusy = true;
      let payLoad = { ...this.filter };
      payLoad.Page = this.filterBranch.page;
      payLoad.take = this.filterBranch.take;
      payLoad.SortType = this.filterBranch.SortType;
      payLoad.SortColumnId = this.filterBranch.SortColumnId;

      const res = await this.$report.post(
        `/dashboard/CouponAnalysis/Branch`,
        payLoad
      );

      this.itemsBranch = res.data.detail.revenueBranchChannelResponses;
      this.rowsBranch = res.data.detail.totalRecord;
      this.isBusy = false;
    },
    async getProduct() {
      this.isProductBusy = true;
      let payLoad = { ...this.filter };

      payLoad.Page = this.filterProductTable.page;
      payLoad.search = this.filterProductTable.search;
      payLoad.take = this.filterProductTable.take;
      payLoad.SortType = this.filterProductTable.SortType;
      payLoad.SortColumnId = this.filterProductTable.SortColumnId;
      const res = await this.$report.post(
        `/dashboard/CouponAnalysis/Product`,
        payLoad
      );

      this.itemsProduct = res.data.detail.data;
      this.rowsProduct = res.data.detail.total;
      this.isProductBusy = false;
    },

    pagination(val) {
      this.filterTable.page = val;

      this.getCoupon();
    },
    paginationBranch(val) {
      this.filterBranch.page = val;

      this.getBranch();
    },
    paginationProduct(val) {
      this.filterProductTable.page = val;

      this.getProduct();
    },
    handleChangeTake(val) {
      this.filterTable.page = 1;

      this.filterTable.take = val;
      this.getCoupon();
    },
    handleChangeTakeBranch(val) {
      this.filterBranch.page = 1;

      this.filterBranch.take = val;
    this.getBranch();
    },
    handleProductChangeTake(val) {
      this.filterProductTable.page = 1;

      this.filterProductTable.take = val;
      this.getProduct();
    },
    async exportExcel(mode, name) {
      try {
        this.filter.Mode = mode;

        let payLoad = { ...this.filter };

        this.$bus.$emit("showLoading");

        const res = await this.$report.post(
          `/dashboard/Export/CouponAnalysis`,
          payLoad,
          {
            responseType: "blob",
          }
        );

        let data = res;

        var fileURL = window.URL.createObjectURL(new Blob([data.data]));
        var fileLink = document.createElement("a");
        // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", name + `.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.$bus.$emit("hideLoading");
      } catch (error) {
        console.error(error);
        this.errorAlert(error.message);
        this.$bus.$emit("hideLoading");
      }
    },
    exportModal() {
      this.$refs.ModalInputEmail.show();
    },
    async exportCouponCustomer() {
      try {
        this.$bus.$emit("showLoading");

        const res = await this.$report.post(
          `/DashBoard/Export/coupon_customer`,
          this.filter,
          {
            responseType: "blob",
          }
        );

        if (res.status == 200) {
          this.confirmAlert({
            message: "Do you want to download the file now?",
            title: "Success !",
            icon: "success",
            confirmButtonText: "Go To Page",
            cancelButtonText: "Close",
          }).then((val) => {
            if (val.isConfirmed) {
              // this.deleteItem(id);
              const routeData = this.$router.resolve({
                name: "Customer Report List",
              });
              window.open(routeData.href, "_blank");
            }
          });
        } else {
          this.errorAlert(res.data.message);
        }
        this.$bus.$emit("hideLoading");
      } catch (error) {
        console.error(error);
        this.errorAlert(error.message);
        this.$bus.$emit("hideLoading");
      }
    },
    filterActions() {
      if (this.filter.LastType == 1) {
        this.filter.DateFrom = moment()
          .subtract(1, "week")
          .format("YYYY-MM-DD");
        this.filter.DateTo = moment().format("YYYY-MM-DD");
      } else if (this.filter.LastType == 2) {
        this.filter.DateFrom = moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD");
        this.filter.DateTo = moment().format("YYYY-MM-DD");
      } else if (this.filter.LastType == 3) {
        this.filter.DateFrom = moment()
          .subtract(1, "years")
          .format("YYYY-MM-DD");
        this.filter.DateTo = moment().format("YYYY-MM-DD");
      }
      this.filter.TransactionDateFrom = moment(
        this.filter.TransactionDateFrom
      ).format("YYYY-MM-DD");
      this.filter.TransactionDateTo = moment(
        this.filter.TransactionDateTo
      ).format("YYYY-MM-DD");

      this.getData();
    },
    getDataFilter() {
      return this.options.dateType.find(
        (el) => el.value == this.filter.LastType
      ).text;
    },
    clearFilter() {
      this.filter = {
        DateFrom: "",
        DateTo: "",
        TransactionDateFrom: moment().subtract(1, "month").format("YYYY-MM-DD"),
        TransactionDateTo: moment().format("YYYY-MM-DD"),
        Search: "",
        MemberTier: [],
        BranchFilter: [],
        CouponCode: [],
        PrivilegeCode: [],
        Page: 1,
        GraphType: 1,
        take: 10,
        Mode: 0,
        allow_anonymous: this.$store.state.filter.allow_anonymous,
        LastType: 2,
      };
      // this.filter.LastType = 2;
      this.$store.dispatch("clearFilter");
      this.filterActions();
      this.$refs.sidebarFilter.hide();
    },
    handleSearch() {
      this.getProduct();
    },
    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    filterGraph(val) {
      this.filter.GraphType = val;
      this.getCouponChart();
    },
    clearExportFilter() {
      this.filterExportFile = {
        email: "",
      };
    },
    sortingCoupon(ctx) {
      let index = this.fields.findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;

      if (
        this.filterTable.SortType &&
        !ctx.sortDesc &&
        this.filterTable.SortColumnId == index
      ) {
        this.filterTable.SortColumnId = 0;
        this.filterTable.SortColumnId = 0;
        this.filterTable.sortBy = "";
        this.filterTable.sortDesc = "";
      } else {
        this.filterTable.SortColumnId = index;
        this.filterTable.SortType = ctx.sortDesc;
      }
      this.getCoupon();
    },
    sortingProduct(ctx) {
      var index = 0;
      if (ctx.sortBy == "percenSalesContribution") {
        index = this.fieldsProduct.findIndex((el) => el.key == "totalSales");
        index = index + 1;
      } else {
        index = this.fieldsProduct.findIndex((el) => el.key == ctx.sortBy);
        index = index + 1;
      }
      if (
        this.filterProductTable.SortType &&
        !ctx.sortDesc &&
        this.filterProductTable.SortColumnId == index
      ) {
        this.filterProductTable.SortColumnId = 0;
        this.filterProductTable.SortColumnId = 0;
        this.filterProductTable.sortBy = "";
        this.filterProductTable.sortDesc = "";
      } else {
        this.filterProductTable.SortColumnId = index;
        this.filterProductTable.SortType = ctx.sortDesc;
      }
      this.getProduct();
    },
    sortingChanged(ctx) {
      let index = this.fieldsBranch.findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;

      if (
        this.filterTable.SortType &&
        !ctx.sortDesc &&
        this.filterTable.SortColumnId == index
      ) {
        this.filterTable.SortColumnId = 0;
        this.filterTable.SortColumnId = 0;
        this.filterTable.sortBy = "";
        this.filterTable.sortDesc = "";
      } else {
        this.filterTable.SortColumnId = index;
        this.filterTable.SortType = ctx.sortDesc;
      }
      this.getBranch();
    },
  },
};
</script>

<style>
.text-highlight {
  color: var(--primary-color);
  font-weight: bold;
  opacity: 1 !important;
}
.text-filter {
  opacity: 0.5;
}
.w-40 {
  width: 40%;
}
.w-15 {
  width: 15%;
}
.w-auto {
  width: auto;
}
</style>
